import React from 'react';
import FacebookLogin from 'react-facebook-login';
import Cookies from 'js-cookie';
 
const responseFacebook = (response) => {
  console.log(response);

     // If login successful, set cookie
     Cookies.set('token', response.accessToken, { expires: 7, secure: true, sameSite: 'strict' });

   //  Cookies.set('CCCL:', username, { expires: 7 }); // Cookie expires in 7 days
}

const componentClicked = () => {
    console.log('Clicked');
  }
 
const FaceBookAuthButton = () => {
    
    return(
        <FacebookLogin
        appId="8146990888763560"
        autoLoad={true}
        fields="name,email"
        onClick={componentClicked}
        callback={responseFacebook} />
    
    );
};


export default FaceBookAuthButton;

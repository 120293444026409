import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Cookies from 'js-cookie';


const steps = [
  'Select master blaster campaign settings',
  'Create an ad group',
  'Create an ad',
];

const token = Cookies.get('CCCL');
console.log('this is a cookie:' + token);




export default function HorizontalLinearAlternativeLabelStepper() {
  return (
      <Stepper activeStep={1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
  );
}
import { GoogleLogin } from "@leecheuk/react-google-login";
import { GoogleOAuthenProvider} from '@react-oauth/google';
import Cookies from 'js-cookie';
//import { useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';






const clientid = '583577542939-918bu24tc9cl7a2d92tt7rec6dh53khq.apps.googleusercontent.com';
            const GoogleAuthButton = ({ onSuccess, onFailure }) => {
                
const HandleSuccess = (response) => {
    console.log(response);
    Cookies.set('CCCL', response.accessToken, { expires: 7, secure: true, sameSite: 'strict' });
    postGoogleAccount(response);

  // SomeFunction();
   //const navigate = useNavigate();
    ///navigate(`/Account`);
   // postScheduledJob(response);
   handleClick();
}

const postGoogleAccount = async (response) => {
    var customer = 0;
    postGoogleAccountasync(response).then(cust => {
       // customer = cust;
       // redirectToCheckout();
    //console.log(cust.customerIDdata); // fetched movies
  }).then(async function() {
    console.log(customer);
    //alert("All Done!")
    //navigate(`/home`);
    console.log('success');
    //    successUrl: `${window.location.origin}/success/{CHECKOUT_SESSION_ID}/${data.houseID}/${customer}/${email}`,
     })
  };

  async function postGoogleAccountasync(response) {
   // console.log(response.profileObj);
    // const response2 = await  fetch('https://prod.carrollcountychristmaslights.com/api/Account/Google',{
     const response2 = await  fetch('https://localhost:7080/api/Account/Google',{
         method: 'POST',
         headers:{'Content-Type':'application/json'},
           body: JSON.stringify({  "accessToken": response.accessToken,
           "email": response.profileObj.email,
           "name": response.profileObj.name,
           })
       });
     const customerIDdata = await response2.json();
     return customerIDdata;
   };


const onError = (response) => {
    console.log(response);
   // postScheduledJob(response);
}
                const navigate = useNavigate();

                function handleClick() {
              
                  navigate('/Account');
                }
               
                return(
                       <GoogleLogin
                clientId="583577542939-918bu24tc9cl7a2d92tt7rec6dh53khq.apps.googleusercontent.com"
                buttonText="Login With Google"
                onSuccess={HandleSuccess}
                onFailure={onError}
                cookiePolicy={"single_host_origin"}
            />
                
            );
        };

       

            export default GoogleAuthButton;